import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import emailLogo from "images/email-logo.svg";
import twitterLogo from "images/twitter-logo.svg";
import instagramLogo from "images/instagram-logo.svg";
import linkedinLogo from "images/linkedin-logo.svg";
import phoneLogo from "images/phone-logo.svg";
import facebookIcon from "images/facebook-logo.svg";


import Logo from "images/logoFull.png";

import LogoW from "images/logoFullWhite.png";
import LogoZoom from "images/logozoom.png";
import LogoZoomWhite from "images/logozoomWhite.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;


const Plan = styled.a`
  ${tw`w-full max-w-72 mt-16  md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col items-center object-center flex flex-row justify-center leading-relaxed py-8 -mx-8 bg-white rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8 `;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const Image = styled.img(props => [
  tw` h-24 w-24 mb-4`,
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);


export default ({
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
  subheading = "Contact Us",
  description = "You can reach out to us via the following channels", plans = null,
  primaryButtonText = "Buy Now",
  imageContain = false,
  imageShadow = true,
}) => {

  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */




  const defaultPlans = [
    {
      name: "Email",
      image: emailLogo,
      mainFeature: "play@buzza.app",
      buttonText: "",
      link: "mailto:play@buzza.app"
    },
    {
      name: "Twitter",
      image: twitterLogo,
      mainFeature: "@buzza.app",
      buttonText: "",
      link: "https://www.twitter.com/getbuzza_"
    },
    {
      name: "Instagram",
      image: instagramLogo,
      mainFeature: "buzza.app",
      buttonText: "",
      link: "https://www.instagram.com/getbuzza/"
    },
    {
      name: "LinkedIn",
      image: linkedinLogo,
      mainFeature: "Buzza App",
      buttonText: "",
      link: "https://www.linkedin.com/company/buzza-sports/"

    },
    {
      name: "Facebook",
      image: facebookIcon,
      mainFeature: "Buzza App",
      buttonText: "",
      link: "https://www.facebook.com/Buzza-352248298537684"
    },
    {
      name: "Phone",
      image: phoneLogo,
      mainFeature: "+2348114570714",
      buttonText: "",

    }
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />


        {plans.map((plan, index) => (
          <Plan key={index} href={plan.link} target={"_blank"} featured={plan.featured}>
            <PlanHeader>
              <span className="priceAndDuration">

                <Image src={plan.image} />
              </span>
              <span className="name">{plan.name}</span>
              <span className="mainFeature">{plan.mainFeature}</span>
            </PlanHeader>

            {/* <PlanAction>
              <BuyNowButton>{plan.buttonText}</BuyNowButton>
            </PlanAction> */}
          </Plan>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
