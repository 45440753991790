import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Pricing />
      {/* <Testimonial
        heading="Our Paying Customers"
      /> */}
      <FAQ
        // imageSrc={customerSupportIllustrationSrc}
        // imageContain={true}
        // imageShadow={false}
        description="Here are some frequently asked questions about Buzza from our loving customers. Should you have any other questions, feel free to reach out via our contact channels"
        subheading="FAQs"
        heading={
          <>
            Frequently Asked  <span tw="text-primary-500">Questions </span>
          </>
        }
      />
      <Footer />
    </AnimationRevealPage>
  );
};
