import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import sportsGifSrc from "images/sportsgif.gif";





const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 py-2 mb-6 font-buzzz transform text-center rounded-full inline-block`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Bring your sporting activities to life!"
        description="Every effort counts, every game tells a unique story"
        buttonRounded={false}
        primaryButtonText="Get Started"
        imageSrc={sportsGifSrc}

      />
      <MainFeature
        heading={<>Built by and for those who <HighlightedText>love sports</HighlightedText></>}
        description="We are revolutionizing the way we play sports by equipping organisations and individuals with ground breaking tools and features that would foster the growth of sporting communities."
      />


      <Features
        subheading={<Subheading>Culture</Subheading>}
        heading="Our Perspectives"
        description="`The most valuable player is the one who makes the most players valuable`"
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Our Vision",
            description: "To enable sporting communities worldwide thrive, excel and reach their full potentials. We believe high quality sport should be accessible to anyone from anypart of the world"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Our Mission",
            description: "To be the foundation of the thriving sporting ecosystems to bring about positive change to communities in relation to SDG goal 1 (No Poverty) and goal 3 (Good Health and Well-being.) "
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Our Motivation",
            description: "Where else can a kick of a ball bring the world to a halt, a dunk change the course of history or a forehand smash insipre generations unborn!. This is the power of sports, the magical moments , the frustrating losses and the never ending fun. THIS IS WHY WE PLAY!!"
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid
        subheading={<Subheading>Our Team</Subheading>}
      /> */}

      <Footer />
    </AnimationRevealPage>
  );
};
