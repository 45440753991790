import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import MainFeature from "components/features/ThreeColCenteredStats.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import sportsGifSrc from "images/impact.jpg";





const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 py-2 mb-6 font-buzzz transform text-center rounded-full inline-block`;
export default () => {
    return (
        <AnimationRevealPage>
            <Header />
            <MainFeature1
                subheading={<Subheading></Subheading>}
                heading="We are Social Impact Driven"
                description="Why do you play? What Impact do you make in your community?"
                buttonRounded={false}
                primaryButtonText="Make an impact"
                imageSrc={sportsGifSrc}

            />
            <MainFeature
                subheading=""
                heading="Together, yes we can!"
                description="Our mission is to create a better world where poverty is eliminated, health and well-being are optimised, and cities are inclusive, secure, resilient and sustainable through the transformative power of sports. "
                stats={[
                    {
                        key: "Impact Campaigns",
                        value: "Join our",
                    },
                    {
                        key: "Grow your Revenue",
                        value: "Improve",
                    },
                    {
                        key: "Connect and expand",
                        value: "Automate",
                    },
                ]
                }
            />




            <Footer />
        </AnimationRevealPage>
    );
};
