import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderContainer = tw.div`w-auto flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full  lg:text-5xl text-3xl`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block mx-4 w-5/6 max-w-md sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-48  py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;



const ExplainContainer = tw.div`flex justify-center mt-8 mx-4 flex-col lg:flex-row items-center lg:items-start relative`;


const PlansContainer = tw.div`flex justify-center  flex-col lg:flex-row items-center lg:items-start relative`;
const Plan = styled.div`
  ${tw`w-5/6 max-w-72 mt-8 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-2xl sm:text-3xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .size {
    ${tw`text-md font-bold  text-primary-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
  subheading = "Pricing",
  heading = "Business/Organisation Plans.",
  description = "Fair and feature rich plans to achieve your goals!",
  plans = null,
  primaryButtonText = "Subscribe Now",
  planDurations = [
    {
      text: "Month",
      switcherText: "Monthly",
    },
    {
      text: "Year",
      switcherText: "Yearly  (40% Off)*",
    }
  ]
}) => {
  const defaultPlans = [
    {
      name: "Rookie Plan",
      durationPrices: ["₦10,000", "₦75,000"],
      mainFeature: "For small sport communities",
      planSize: "0-50 members",
      features: ["Team Formation Setup", "Support for 40+ Sports", "Schedule trainings & ceremonies", "Assign Roles and Permissions"],
      featured: true,
      hasPrice: true
    },
    {
      name: "Amateur Plan",
      durationPrices: ["₦40,000", "₦300,000"],
      mainFeature: "Suited for growing sport communities",
      planSize: "51-200 members",
      features: ["Everything in Rookie", "Realtime Match Management", "Video Support", "Competition Statistics", "8+ Competition Systems"],
      featured: true,
      hasPrice: true
    },
    {
      name: "Pro Plan",
      durationPrices: ["₦120,000", "₦1,000,000"],
      mainFeature: "Suited for standard sport communities",
      planSize: "201-500 members",
      features: ["Everything in Amateur", "Automated Match Reports", "Sponsorship acquisition support", "Auto Update Push Notifications", "Assisted Growth Marketing", "Lifetime Growth Updates"],
      featured: true,
      hasPrice: true
    },
    {
      name: "Enterprise Plan",
      durationPrices: ["Contact Sales", "Contact Sales"],
      mainFeature: "Suited for large-scale sport communities",
      planSize: "500+ members",
      features: ["Everything in Pro", "Custom Landing Pages", "Custom Tech Support", "Event Management Planning & Assistance", "Dedicated Customer Support Specialist"],
      featured: true,
      hasPrice: false
    }
  ];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(1);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
          <PlanDurationSwitcher>
            {planDurations.map((planDuration, index) => (
              <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)}>{planDuration.switcherText}</SwitchButton>
            ))}
          </PlanDurationSwitcher>


        </HeaderContainer>
        < ExplainContainer>*members refer to players, coaches and managers in your sport community </ ExplainContainer>

        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>

                <span style={!plan.hasPrice ? { display: "none" } : { display: "block" }} className="priceAndDuration">
                  <span className="price">{plan.durationPrices[activeDurationIndex]}</span>



                  <span className="slash"> / </span>
                  <span className="duration">{planDurations[activeDurationIndex].text}</span>

                </span>

                <span style={plan.hasPrice ? { display: "none" } : { display: "block" }} className="priceAndDuration">
                  <span className="price">{plan.durationPrices[activeDurationIndex]}</span>


                </span>



                <span className="name">{plan.name}</span>

                <span className="mainFeature">{plan.mainFeature}</span>

                <span className="size">{plan.planSize}</span>


              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              {/* <PlanAction>
                <BuyNowButton>{primaryButtonText}</BuyNowButton>
              </PlanAction> */}
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container >
  );
};
