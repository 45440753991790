import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  a {
    ${tw`mt-2 text-blue-600`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: April 10, 2023</p>

            <article class="col-lg-7 col-md-9" id="legal-body">
              <h1>Buzza Privacy Policy</h1>
              <p class="effective-date">Effective Date: 15 April 2023</p>
              <section id="principles">
                <p>Your privacy is very important to us. Before we get to the details, check out our Privacy Label to see a summary of our privacy practices. Click on the links to learn more and read the full policy below.</p>
                <a class="jump-link" href="#full_policy">Jump to full privacy policy</a>
              </section>
              <section id="label">
                <h2>Privacy Label</h2>
                <section class="label-section">
                  <h3 class="label-heading">Collection and Sale of Data</h3>
                  <ul>

                    <li class="label-item">
                      <span class="description">Do we sell your personal information?</span>
                      <span class="value"><a href="#aggregate_information">No</a></span>
                      <div class="clear"></div>
                    </li>

                    <li class="label-item">
                      <span class="description">Are our employees, agents, contractors, and partners compliant with Google API Services User Data Policy?</span>
                      <span class="value"><a href="#google_compliant">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Do we share aggregate information?</span>
                      <span class="value"><a href="#aggregate_information">Yes, only with the your consent to improve our product offering</a></span>
                      <div class="clear"></div>
                    </li>



                    <li class="label-item">
                      <span class="description">Do we  delete your data when you request account deletion?</span>
                      <span class="value"><a href="#deleting">Yes, processing takes 1 month</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Do we retain your data for as long as we need it unless you request deletion?</span>
                      <span class="value"><a href="#retention">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                  </ul>
                </section>
                <section class="label-section">
                  <h3 class="label-heading">Privacy Tools and Controls</h3>
                  <ul>
                    <li class="label-item">
                      <span class="description">Can you control who sees your activity and content?</span>
                      <span class="value"><a href="#sharing">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Can you control who sees your location-based activity?</span>
                      <span class="value"><a href="#sharing">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Are your activity and profile privacy controls set to “Everyone” by default?</span>
                      <span class="value"><a href="#public_information">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Can you download and delete your data?</span>
                      <span class="value"><a href="#deleting">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Do all members worldwide have the same suite of tools and controls?</span>
                      <span class="value"><a href="#legal_bases">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                  </ul>
                </section>
                <section class="label-section">
                  <h3 class="label-heading">Tracking</h3>
                  <ul>

                    <li class="label-item">
                      <span class="description">Do we track your device location while you are not using the app?</span>
                      <span class="value"><a href="#location">No</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Do we use cookies?</span>
                      <span class="value"><a href="#technical">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Do we track your browsing activities on other sites?</span>
                      <span class="value">No</span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Do we listen to you using your device microphone?</span>
                      <span class="value">No</span>
                      <div class="clear"></div>
                    </li>
                  </ul>
                </section>
                <section class="label-section">
                  <h3 class="label-heading">Communication</h3>
                  <ul>
                    <li class="label-item">
                      <span class="description">Do we give you advance notice when we make important changes to our Privacy Policy?</span>
                      <span class="value"><a href="#privacy_policy_information">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Do we send you marketing communications with opt out?</span>
                      <span class="value"><a href="#to_communicate_with_you">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                    <li class="label-item">
                      <span class="description">Do we send you push notifications with opt out?</span>
                      <span class="value"><a href="#to_communicate_with_you">Yes</a></span>
                      <div class="clear"></div>
                    </li>
                  </ul>
                </section>
              </section>
              <section id="introduction">
                <a name="full_policy"></a>
                <h2>Privacy Policy</h2>
                <h3>Introduction</h3>
                <p>Our privacy policy (the “Privacy Policy”) explains the information we collect, how we use and share it, how to manage your privacy controls and your rights in connection with our websites and the related mobile applications and services (collectively, the “Services”). Please also read our <a href="https://buzza.app/legal/terms">Terms of Service</a> which sets out the terms governing the Services.</p>
                <p>Buzza is headquartered in Lagos and our Services are provided to you by Buzza, Inc. If you are a resident of the European Economic Area (“EEA”), Buzza, Inc. is the controller of your personal data for the purposes of EEA data protection law.</p>
                <p>This policy was written in English. To the extent a translated version conflicts with the English version, the English version controls. Unless indicated otherwise, this Privacy Policy does not apply to third party products or services or the practices of companies that we do not own or control, including other companies you might interact with on or through the Services.</p>
                <p>Questions or comments about this Privacy Policy may be submitted by mail to the address below or via <a href="https://support.buzza.app">https://support.buzza.app</a>.</p>
                <address>
                  <b>Buzza, Inc.</b>
                  <br />

                  <br />
                  Lagos , LOS 100225
                  <br />
                  NG
                  <br />
                  Attn: Legal
                  <br />
                  <a href="mailto:support@buzza.app">
                    support@buzza.app
                  </a>
                </address>
              </section>
              <section id="information_buzza_collects">
                <a name="information-buzza-collects"></a>
                <h2>Information Buzza Collects</h2>
                <p>Buzza collects information about you, including information that directly or indirectly identifies you, if you or your other members choose to share it with Buzza. We receive information in a few different ways, including when you track, complete or upload activities using the Services. Buzza also collects information about how you use the Services. There are also several opportunities for you to share information about yourself, your friends, and your activities with Buzza. For example:</p>
                <section id="account_profile_activity_and_use_information">
                  <h3>Account, Profile, Activity, and Use Information</h3>
                  <p>We collect <b>basic account information</b> such as your name, email address, date of birth, gender, weight, username and password that helps secure and provide you with access to our Services.</p>
                  <p><b>Profile, activity and use information</b> is collected about you when you choose to upload a picture, activity (including date, time and geo-location information as well as your speed and pace and perceived exertion) or post, join a challenge, add your equipment usage, view others’ activities, or otherwise use the Services.</p>
                  <p>We use your <b>contact information</b> so we can respond to your support requests and comments.</p>
                </section>

                <section id="content_you_share">
                  <h3>Content You Share</h3>
                  <p>We gather information from the photos, posts, comments, kudos, ratings, reviews, and <b>other content you share on the Services</b>, including when you participate in partner events or create segments or routes.</p>
                </section>

                <section id="connected_devices_and_apps">
                  <h3>Connected Devices and Apps</h3>
                  <p>Buzza collects information from <b>devices and apps you connect to Buzza</b>. For example, you may connect your Garmin watch or Flywheel account to Buzza and information from these devices and apps will be passed along to Buzza.</p>
                </section>

                <section id="payment_information">
                  <h3>Payment Information</h3>
                  <p>When you make a payment on Buzza, you may provide <b>payment information</b> such as your payment card or other payment details. We use Payment Card Industry compliant third-party payment services and we do not store your credit card information.</p>
                </section>
                <section id="third_party_accounts">
                  <h3>Third-Party Accounts</h3>
                  <p>Buzza allows you to sign up and log in to the Services using accounts you create with third-party products and services, such as Facebook, Google, or Apple (collectively, “Third-Party Accounts”). If you access the Services with <b>Third-Party Accounts</b> we will collect information that you have agreed to make available such as your name, email address, profile information and preferences. This information is collected by the Third-Party Account provider and is provided to Buzza under their privacy policies. You can generally control the information that we receive from these sources using the privacy controls in your Third-Party Account.</p>
                </section>
                <section id="technical_information">
                  <a name="technical"></a>
                  <h3>Technical Information and Log Files</h3>
                  <p>We collect information from your browser, computer, or mobile device, which provide us with technical information when you access or use the Services. This technical information includes device and network information, cookies, log files and analytics information. Learn more about how we use cookies and manage your preferences by visiting our <a href="https://www.buzza.app/legal/cookie_policy">Cookies Policy</a>.</p>
                  <p>The Services use <b>log files</b>. The information stored in those files includes IP addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks. This information is used to analyze trends, administer, protect and secure the Services, track member movement in the aggregate, and gather broad demographic information for aggregate use. IP addresses may be linked to session IDs, athlete IDs and device identifiers.</p>
                </section>
                <section id="other_information">
                  <h3>Other Information</h3>
                  <p>We may <b>collect information from you through third parties</b>, such as when we collect your feedback through surveys.</p>
                  <p>We may also collect <b>information about you from other members</b> such as when they give you kudos or comment on your activities.</p>
                </section>
              </section>
              <section id="how_buzza_uses_information">
                <a name="how-buzza-uses-information"></a>
                <h2>How Buzza Uses Information</h2>
                <p>Buzza uses the information we collect and receive as described below.</p>
                <section id="to_provide_the_services">
                  <a name="to-provide-the-services"></a>
                  <h3>To provide the Services</h3>
                  <p>We use the information we collect and receive to provide the Services, including providing you with the ability to:</p>
                  <ul>
                    <li><b>Record your activities and your performance.</b> For example, to compare your past efforts, analyze your schedule, and (with your consent) use your heart rate information to provide you with interesting and useful performance analysis.</li>
                    <li><b>Interact with other athletes.</b> For example, to compete on segments, participate in clubs, challenges, or events, follow other athletes, and use features that help athletes interact with one another, such as group activities or flyby.</li>
                    <li><b>Manage your schedule.</b> For example, to set goals and use your schedule dashboard.</li>
                    <li><b>Explore new places to go.</b> For example, to discover routes or segments where you can run or ride.</li>
                    <li>To <b>visualize your activities</b> in new ways. For example, by creating personal heatmaps or using your schedule log.</li>
                  </ul>
                  <p>Subject to your <a href="https://www.buzza.app/settings/privacy">privacy controls</a>, your information, including parts of your profile, username, photos, members you follow and who follow you, clubs you belong to, your activities, the devices you use, and kudos and comments you give and receive will be shared on Buzza so that you may be able to participate in the Services, for example to show your place on a leaderboard. Certain <a href="https://support.buzza.app/hc/articles/360034758331&amp;sa=D&amp;ust=1570469544619000&amp;usg=AFQjCNFLQTZCb0JW-CwDLIHMnMZ2eGrHKw">information</a> (e.g., your name, and some profile information) is also available to non-members on the web. Your precise location information, such as where you run or ride, may also be shared on Buzza or to non-members, in accordance with your privacy controls.</p>
                </section>
                <section id="to_customize_the_experience">
                  <a name="to-customize-the-experience"></a>
                  <h3>To customize your experience</h3>
                  <p>We use the information we collect about you, your followers, and your activities to customize your experience. For example, we may suggest segments, routes, challenges, or clubs that may interest you, athletes that you may want to follow, or new features that you may want to try. If we know that you like to run, we may tell you about new running activities or show you sponsored content related to running. If we see that you run in a certain area, we may suggest a race in that area.</p>
                </section>
                <section id="to_protect_you_and_the_services">
                  <a name="to-protect-you-and-the-services"></a>
                  <h3>To protect you and the Services</h3>
                  <p>We use the information we collect to protect members, enforce our <a href="https://www.buzza.app/legal/terms">Terms of Service</a> and <a href="https://www.buzza.app/community-standards">Community Standards</a>, and promote safety. For example, we find and remove content that violates our terms, such as hate speech or spam, as well as suspend or terminate accounts that share such content.</p>
                </section>
                <section id="to_improve_our_services">
                  <a name="to-improve-our-services"></a>
                  <h3>To improve our Services</h3>
                  <p>We also use the information we collect to analyze, develop and improve the Services. To do this, Buzza may use third-party analytics providers to gain insights into how our Services are used and to help us improve the Services.</p>
                </section>
                <section id="to_communicate_with_you">
                  <a name="to-communicate-with-you"></a>
                  <h3>To communicate with you</h3>
                  <p>We use the information we collect to provide support in response to your requests and comments.</p>
                  <p>We may also use the information we collect to market and promote the Services, activities and events on Buzza, and other commercial products or services. This includes marketing and push communications, where you have not opted out of receiving such messages and notifications.</p>
                </section>
                <section id="to_process_your_subscription">
                  <a name="to-process-your-subscription"></a>
                  <h3>To process your subscription</h3>
                  <p>We use the information we collect to process your subscription.</p>
                </section>
                <section id="aggregate_information">
                  <a name="aggregate_information"></a>
                  <h3>Aggregate Information</h3>
                  <p><b>We do not sell your personal information</b>. Buzza may aggregate the information you and others make available in connection with the Services and post it publicly or share it with third parties. Examples of the type of information we may aggregate include information about equipment, usage, demographics, routes and performance. </p>
                </section>

                <section id="google_compliant">
                  <a name="google_compliant"></a>
                  <h3>Google compliant</h3>
                  <p> We use and transfer  information received from Google APIs and adhere to Google API Services User Data Policy, including the Limited Use requirements. <a href="https://developers.google.com/terms/api-services-user-data-policy">Google policy</a></p>
                </section>
              </section>
              <section id="how_information_is_shared">
                <a name="how-information-is-shared"></a>
                <h2>How Information is Shared</h2>
                <p>In addition to sharing aggregated data about our members as described above, we also share personal information in accordance with your preferences, as needed to run our business and provide the Services, and where required for legal purposes, as set forth below.</p>
                <section id="service_providers">
                  <h3>Service Providers</h3>
                  <p>We may share your information with third parties who provide services to Buzza such as supporting,  improving, promoting and securing the Services, processing payments, or fulfilling orders. These service providers only have access to the information necessary to perform these limited functions on our behalf and are required to protect and secure your information. We may also engage service providers to collect information about your use of the Services over time on our behalf, so that we or they may promote Buzza or display information that may be relevant to your interests on the Services or other websites or services.</p>
                </section>
                <section id="publicly_available_information">
                  <a name="public_information"></a>
                  <h3>Publicly Available Information</h3>
                  <p>When you join the Buzza community, your profile and your activities are set to be viewable by <a href="https://support.buzza.app/hc/articles/360034758331&amp;sa=D&amp;ust=1570469544826000&amp;usg=AFQjCNHAsHZ6e7c7CDxNVGzTr6bXjjfIiA">everyone</a> by default. Your name and other <a href="https://support.buzza.app/hc/articles/115000164850-Profile-Page-Privacy-Controls">profile information</a> is viewable by other Buzza members and the public and, subject to your privacy controls, additional information and content you share may also be viewable. For example, your photos and routes may be accessed by other Buzza members and non-members or viewable on publicly accessible Buzza pages or in search engine results. We provide a <a href="https://www.buzza.app/settings/privacy">variety of tools</a> to control the sharing of your information and encourage our members to adjust their controls to align with their desired experience.</p>
                </section>
                <section id="sharing_information_and_activities">
                  <a name="sharing"></a>
                  <h3>Sharing Information and Activities</h3>
                  <p>As a social network, we share your information with other Buzza members and the public in accordance with your privacy controls. You can change the <a href="https://support.buzza.app/hc/articles/360034758331">visibility</a> of the activities you upload to Buzza. Also, you can tell us about important locations, such as your home or work address, by enabling a <a href="https://buzza.app/settings/privacy">Privacy Zone</a> in your settings. We will make private any portion of your activity that starts or ends in your Privacy Zone. You can choose to invite your contacts to view your location and activities using <a href="https://blog.buzza.app/summit-beacon/">Buzza </a>. You can also <a href="https://support.buzza.app/hc/articles/221089587-Sharing-your-Buzza-Activities">share</a> your activities with your <a href="https://support.buzza.app/hc/articles/216919127-Finding-Friends-and-Managing-Contacts-on-Buzza">contacts</a> via text, email, or on social media applications like Facebook, Instagram, and Twitter. If you link your Buzza account to other social networks and share your activities, they will be viewable on such third-party platforms, including your location information. You should use caution when sharing information via third parties and you should carefully review the privacy practices of such third parties.</p>
                </section>

                <section id="affiliates_and_acquirors">
                  <h3>Affiliates and Acquirors of our Business or Assets</h3>
                  <p>We may share your information with affiliates under common control with us, who are required to comply with the terms of this Privacy Policy with regard to your information. If Buzza becomes involved in a business combination, securities offering, bankruptcy, reorganization, dissolution or other similar transaction, we may share or transfer your information in connection with such transaction.</p>
                </section>
                <section id="legal_requirements">
                  <a name="legal-requirements"></a>
                  <h3>Legal Requirements</h3>
                  <p>We may preserve and share your information with third parties, including law enforcement, public or governmental agencies, or private litigants, within or outside your country of residence, if we determine that such disclosure is allowed by the law or reasonably necessary to comply with the law, including to respond to court orders, warrants, subpoenas, or other legal or regulatory process. We may also retain, preserve or disclose your information if we determine that this is reasonably necessary or appropriate to prevent any person from death or serious bodily injury, to address issues of national security or other issues of public importance, to prevent or detect violations of our <a href="https://buzza.app/legal/terms">Terms of Service</a> or fraud or abuse of Buzza or its members, or to protect our operations or our property or other legal rights, including by disclosure to our legal counsel and other consultants and third parties in connection with actual or potential litigation.</p>
                </section>
                <section id="dmca_notices">
                  <h3>DMCA Notices</h3>
                  <p>We may share your information with third parties when we forward <a href="https://buzza.app/legal/terms#claims-of-infringement">Digital Millennium Copyright Act</a> (DMCA) notifications, which will be forwarded as submitted to us without any deletions.</p>
                </section>
              </section>
              <section id="how_we_protect_information">
                <h2>How We Protect Information</h2>
                <p>We take several measures to safeguard the collection, transmission and storage of the data we collect. We employ reasonable protections for your information that are appropriate to its sensitivity. The Services use industry standard Secure Sockets Layer (SSL) technology to allow for the encryption of personal information and credit card numbers. Buzza engages providers that are industry leaders in online security, including Services verification, to strengthen the security of our Services. The Services are registered with site identification authorities so that your browser can confirm Buzza’s identity before any personal information is sent. In addition, Buzza’s secure servers protect this information using advanced firewall technology.</p>
              </section>
              <section id="managing_your_settings">
                <h2>Managing Your Settings</h2>
                <section id="privacy_controls">
                  <h3>Privacy Controls</h3>
                  <p>Buzza offers several features and settings to help you manage your privacy and share your activities. Most privacy controls are located in your privacy controls page, but some are specific to individual activities, athletes, or <a href="https://support.buzza.app/hc/articles/216918387-Buzza-Routes">routes</a>. Buzza provides you the option to <a href="https://support.buzza.app/hc/articles/216919377-Activity-Privacy-Controls">make your activities private. </a><a href="https://www.buzza.app/settings/privacy">Click here</a> to manage your privacy controls.</p>
                </section>
                <section id="adjust_preferences">
                  <h3>Adjust Notification and Email Preferences</h3>
                  <p>Buzza offers various ways to manage the notifications you receive. You can choose to stop receiving certain emails and notifications by indicating your preference <a href="https://www.buzza.app/settings/email">here</a>. You may also unsubscribe by following the instructions contained at the bottom of marketing or promotional emails. Any administrative or service-related emails (to confirm a purchase, or an update to this Privacy Policy or our <a href="https://buzza.app/legal/terms">Terms of Service</a>, etc.) generally do not offer an option to unsubscribe as they are necessary to provide the Services you requested.</p>
                </section>
                <section id="updating_account_information">
                  <h3>Updating Account Information</h3>
                  <p>You may correct, amend or update profile or account information at any time by adjusting that information in your account settings. If you need further assistance correcting inaccurate information, please contact Buzza at <a href="https://support.buzza.app">https://support.buzza.app</a>.</p>
                </section>
                <section id="deleting_and_downloading">
                  <a name="deleting"></a>
                  <h3>Deleting Information and Accounts and Downloading Your Data</h3>
                  <p>You can delete your account or download your data using our self-service tools. Click <a href="https://www.buzza.app/account">here</a> to download your data, including your activity files. To request that your account is deleted, click <a href="https://www.buzza.app/account">here</a>.</p>
                  <p>After you make a deletion request, we permanently and irreversibly delete your personal data from our systems, including backups. Once deleted, your data, including your account, activities and place on leaderboards cannot be reinstated. Following your deletion of your account, it may take up to 90 days to delete your personal information and system logs from our systems. Additionally, we may <a href="#retention">retain</a> information where deletion requests are made to comply with the law and take other actions permitted by law.</p>
                  <p>Note that content you have shared with others, such as photos, or that others have copied may also remain visible after you have deleted your account or deleted specific information from your own profile. Your public profile may be displayed in search engine results until the search engine refreshes its cache.</p>
                  <p>Buzza also provides you the option to remove individual activities you have posted without deleting your account. Once removed,the activities may remain on Buzza’s systems until you delete your account.</p>
                </section>
              </section>
              <section id="legal_bases">
                <a name="legal_bases"></a>
                <h2>Your Rights and Our Legal Bases</h2>
                <p>We provide the same suite of privacy tools and controls to all of our members worldwide. Particular rights may be available to you if you reside in certain locations, such as the <a href="https://www.buzza.app/legal/legal_bases#eea_rights">EEA</a>, <a href="https://www.buzza.app/legal/legal_bases#brazil_rights">Brazil</a> or <a href="https://www.buzza.app/legal/legal_bases#ca_rights">California</a>. <a href="https://www.buzza.app/legal/legal_bases">Learn more</a> about your rights and how to exercise them.</p>
                <section id="your_legal_rights_in_eea"></section>
                <h3>Your Legal Rights in the EEA</h3>
                <p>If you are habitually located in the EEA, you have the right to access, rectify, download or erase your information, as well as the right to restrict and object to certain processing of your information. Buzza’s lead supervisory authority in the EEA is the Data Protection Commission of Ireland. <a href="https://www.buzza.app/legal/legal_bases">Learn more</a>.</p>
                <section id="our_legal_bases"></section>
                <h3>Our Legal Bases</h3>
                <p>Buzza relies on a number of legal bases to collect, use, share, and otherwise process the information we have about you for the purposes described in this Privacy Policy, including:</p>
                <ul>
                  <li>as necessary to provide the Services and fulfill our obligations pursuant to the <a href="https://buzza.app/legal/terms">Terms of Service</a>. For example, we cannot provide the Services unless we collect and use your location information;</li>
                  <li>where you have consented to the processing;</li>
                  <li>where necessary to comply with a legal obligation, a court order, or to exercise and defend legal claims;</li>
                  <li>to protect your vital interests, or those of others, such as in the case of emergencies; and</li>
                  <li>where necessary for the purposes of Buzza’s or a third party’s <a href="https://www.buzza.app/legal/legal_bases#legitimate_interests">legitimate interests</a>, such as our interests in protecting our members, our partners’ interests in collaborating with our members, and our commercial interests in ensuring the sustainability of the Services.</li>
                </ul>
              </section>
              <section id="transfers">
                <h2>Transfers</h2>
                <p>The Services are operated from the United States. If you are located outside of the United States and choose to use the Services or provide information to us, you acknowledge and understand that your information will be transferred, processed and stored in the United States, as it is necessary to provide the Services and perform the <a href="https://buzza.app/legal/terms">Terms of Service</a>. United States privacy laws may not be as protective as those in your jurisdiction.</p>
              </section>
              <section id="retention">
                <a name="retention"></a>
                <h2>Retention of Information</h2>
                <p>We retain information as long as it is necessary to provide the Services to you and others, subject to any legal obligations to further retain such information. Information associated with your account will generally be kept until it is no longer necessary to provide the Services or until your account is deleted. In addition, you can delete some items of information (e.g., profile information) and you can remove individual activities from view on the Services without deleting your account. For example, after you withdraw your <a href="https://support.buzza.app/hc/en-us/articles/360000556410">consent</a> to Buzza processing your health-related information, Buzza will delete all health-related information you upload. Following your deletion of your account, it may take up to 90 days to fully delete your personal information and system logs from our systems. Additionally, we may retain information to comply with the law, prevent fraud, collect fees, resolve disputes, troubleshoot problems, assist with investigations, enforce the <a href="https://buzza.app/legal/terms">Terms of Service</a> and take other actions permitted by law. The information we retain will be handled in accordance with this Privacy Policy.</p>
                <p>Information connected to you that is no longer necessary and relevant to provide our Services may be de-identified or aggregated with other non-personal data to provide insights which are commercially valuable to Buzza, such as statistics of the use of the Services. For example, we may <a href="https://support.buzza.app/hc/articles/216918827-Delete-your-Buzza-Account">retain</a> publicly available segments or routes and other depersonalized geolocation information to continue to improve the Services and we use aggregated information in Buzza Metro and our Global Heatmap. This information will be de-associated with your name and other identifiers.</p>
              </section>
              <section id="other_sites">
                <h2>Other Buzza Sites</h2>
                <p>Buzza maintains certain websites that can be accessed outside of <a href="https://buzza.app">https://buzza.app</a>, such as <a href="https://blog.buzza.app">https://blog.buzza.app</a> (the “Other Sites”). The Other Sites maintain the look and feel of the Services, but are hosted by outside service providers with their own terms and privacy policies. If you interact with the Other Sites, your information may be stored, processed, or shared outside of the Services. If you interact with the Other Sites, you acknowledge that you may be subject to the terms and conditions and policies applicable to such Other Site. Please be aware that any personal information you submit to the Other Sites may be read, collected, or used by other users of these forums indefinitely, and could be used to send you unsolicited messages. Buzza is not responsible for the personal information you choose to submit via the Other Sites.</p>
              </section>
              <section id="privacy_policy_information">
                <a name="privacy_policy_information"></a>
                <h2>Privacy Policy Information</h2>
                <p>Buzza reserves the right to modify this Privacy Policy at any time. Please review it occasionally. If Buzza makes changes to this Privacy Policy, the updated Privacy Policy will be posted on the Services in a timely manner and, if we make material changes, we will provide a prominent notice. If you object to any of the changes to this Privacy Policy, you should stop using the Services and <a href="https://buzza.app/account">delete your account</a>.</p>
              </section>

              <p class="copyright">©&nbsp; {new Date().getFullYear()} Buzza</p>
            </article>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
