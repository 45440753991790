import React from "react";
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'

import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";




const Container = tw.div`  z-50`;

const Container1 = tw.div`fixed inset-0  z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start `

var Derter = tw.div`w-full flex flex-col  z-50 items-center space-y-4 sm:items-end `;

var Container2 = tw.div`p-4 max-w-sm w-full  z-50  shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden `;



const Container3 = tw.div`flex items-start `;

const Container4 = tw.div`flex-shrink-0 h-6 w-6 text-green-800 `;
const Container4F = tw.div`flex-shrink-0 h-6 w-6 text-red-800 `;
const Container5 = tw.div`ml-3 w-0 flex-1 pt-0`;
const Container6 = tw.div`ml-4 flex-shrink-0 flex`;
const Text1 = tw.p`text-sm font-medium text-gray-400`;
const Text2 = tw.p`mt-1 text-sm text-gray-200`;
var Button = tw.button`h-5 w-5 bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`;



export default function NotificationModal(
    {
        success = true,
        heading = "New Notification",
        description = "Notification Description",
        show = false, setShow

    }
) {


    {
        if (success) {
            Container2 = tw.div`p-4 max-w-sm bg-green-600 w-full  shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden `;
            Button = tw.button`h-5 w-5 bg-green-400 rounded-md inline-flex text-gray-100   hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`

        } else {
            Container2 = tw.div`p-4 max-w-sm w-full bg-red-600 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden `;
            Button = tw.button`h-5 w-5 bg-red-400 rounded-md inline-flex text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`


        }
    }



    return (
        <>
            <Container>
                {/* Global notification live region, render this permanently at the end of the document */}
                <Container1
                    aria-live="assertive"

                >
                    <Derter>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={show}
                            as={Fragment}
                            enter="transform ease-out duration-300 transition"
                            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Container2 bgcolor={success ? "bg-green-500" : "bg-red-500"}>

                                <Container3>

                                    {success ? (
                                        <Container4>

                                            <CheckCircleIcon aria-hidden="true" />

                                        </Container4>
                                    ) : (
                                        <Container4F>

                                            <XCircleIcon aria-hidden="true" />

                                        </Container4F>
                                    )}

                                    <Container5>
                                        <Text1>{success ? ("Success") : ("Error")}</Text1>
                                        <Text2>{description}</Text2>
                                    </Container5>
                                    <Container6>
                                        <Button
                                            onClick={() => {
                                                setShow(false)
                                            }}
                                        >
                                            {/* <span className={tw.span`sr-only`}>Close</span> */}
                                            <XMarkIcon aria-hidden="true" />
                                        </Button>
                                    </Container6>

                                </Container3>
                            </Container2>
                        </Transition>
                    </Derter>
                </Container1>

            </Container>
        </>
    )
}