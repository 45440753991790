import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/star.svg";
import DesignIllustration from "../../images/design-illustration.svg";
import AppStoreBadge from "images/appstore-badge.png";
import PlayStoreBadge from "images/playstore-badge.png";
import { Oval } from 'react-loader-spinner'


const Container = tw.div`relative bg-primary-100  -z-0`;
const TwoColumn = tw.div`flex flex-col justify-between lg:flex-row md:items-center    `;
const LeftColumn = tw.div`relative  lg:w-5/12 pb-12 md:pb-24 pt-40 md:pt-32 lg:pt-0 px-8 sm:px-40 md:px-64 lg:px-24 flex flex-col items-start justify-start  flex-shrink-0 text-left lg:text-left`;
const RightColumn = tw.div`relative lg:mb-0  mt-12   lg:mt-0 flex  flex-col justify-between `;

const Heading = tw.h1`text-left 
font-rebond mb-8 font-black text-4xl sm:ml-0 ml-0 mr-4  justify-center  md:items-center md:text-4xl lg:text-5xl text-secondary-700  leading-tight`;
const Paragraph = tw.p` text-left  mx-0  ml-0 sm:ml-0  mr-4 lg:my-2 pb-8 lg:text-lg text-sm text-secondary-500  `;
const Paragraph2 = tw.p` text-center flex-row text-sm justify-center lg:my-2 my-2 lg:text-xs sm:text-sm text-gray-600  `;

const IllustrationContainer = tw.div`flex object-center  mx-auto  flex-row justify-center md:justify-center  `;


const SideImageDiv = tw.div`flex justify-between flex-row md:items-center    `;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const DecoratorBlob3 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none inline fill-current text-primary-500 relative w-16 h-16 right-0 top-0 transform    -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;

// const ActionsInput = styled.div`
//   ${tw`relative max-w-md text-center  mx-auto flex-row justify-center`}
//   input {
//     ${tw`mx-auto flex-row justify-center sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
//   }
//   button {
//     ${tw` absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 px-4 my-2 sm:my-4 sm:my-2 rounded-full sm:py-4 flex items-center justify-center sm:w-40 leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
//   }
// `;

const WaitlistButtonx = styled.a`
     ${tw` relative mx-auto flex-row left-0 right-0 top-0 bottom-0 bg-primary-500 text-primary-100  px-8 py-3   rounded-full  flex items-center justify-center   leading-none focus:outline-none hover:bg-primary-900 transition  duration-300`}
`
  ;



const AppStoreButton = styled.a`
${tw` relative `}
`
  ;



const PlayStoreButton = styled.a`
     ${tw` relative`}
`
  ;




const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;




export default ({
  heading = "",
  description = "",
  primaryButtonText = "",
  primaryButtonUrl = "#",
  watchVideoButtonText = "Watch Video",
  watchVideoYoutubeUrl = "https://www.youtube.com/embed/_GuOjXYl5ew",
  imageSrc = DesignIllustration,
  imageCss = null,
  imageDecoratorBlob = false,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);


  return (
    <>
      <Header />
      <Container>
        <TwoColumn>



          <LeftColumn>

            <Heading>{heading}   <DecoratorBlob3 /></Heading>
            <Paragraph>{description}   </Paragraph>


            {/* <WaitlistButtonx href="/waitlist">Join our waitlist</WaitlistButtonx> */}

            <SideImageDiv>
              <AppStoreButton target="blank" href="https://itunes.apple.com/lb/app/buzza/id6449469292?mt=8"><img src={AppStoreBadge} width={230}></img></AppStoreButton>
              <PlayStoreButton target="blank" href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.app.buzza"><img src={PlayStoreBadge} width={230}></img></PlayStoreButton>
            </SideImageDiv>



            {/* <ActionsInput>
              <form onSubmit={postEmail}>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your E-mail Address" required />
                {loading ? (
                  <button ><Oval
                    height={25}
                    width={25}
                    color="#fcae93"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#ba4014"
                    strokeWidth={7}
                    strokeWidthSecondary={7}

                  /></button>
                ) : (
                  <button type="submit">Get Started</button>

                )}
              </form>
            </ActionsInput> */}



          </LeftColumn>

          <RightColumn>

            <img
              css={tw`object-contain sm:h-screen max-h-screen`}
              src={imageSrc}

              alt="Hero"
            />


          </RightColumn>
        </TwoColumn>

        <DecoratorBlob1 />




        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
          </div>
        </StyledModal>

      </Container>
    </>
  );
};
