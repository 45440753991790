import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as DownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as UpIcon } from "feather-icons/dist/icons/chevron-up.svg";

const Container = tw.div`relative bg-primary-100`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex flex-col lg:flex-row`;
const Column = tw.div``;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-cover`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center `;
const Heading = tw(SectionHeading)`lg:text-center`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-100 text-gray-900 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);
const Divider = tw.div`my-2 lg:my-8 border-b border-gray-300 w-full`;

export default ({
  subheading = "",
  heading = "Questions",
  description = "Here are some frequently asked questions",

  faqs1 = null,
  faqs2 = null
}) => {
  /*
   * You can modify FAQs either by modifying the below defaultFaqs array or by passing a custom array of FAQs using
   * the faqs prop
   */
  const defaultFaqs1 = [
    {
      question: "What kind of sports can I manage with Buzza?",
      answer:
        "Buzza supports over 40 different sports, including variations. From popular sports such as football, basketball, and tennis, to traditional sports like Abula, Buzza provides you with premium management tools for each sport."
    },
    {
      question: "Can I customize the platform to fit my organization's needs?",
      answer:
        "Depending on your subscription plan, Buzza provides custom support and integration to help ease your tasks and improve efficiency of your organization."
    },
    {
      question: "How does your platform improve community engagement?",
      answer:
        "Buzza is the online community for sports, we enable people easily access sporting communities around them. As the saying goes, if it's not on Buzza it didn't happen!"
    }
  ];

  const defaultFaqs2 = [
    {
      question: "How does your platform help with competition management?",
      answer:
        "Our competition management tools allow you to create and manage tournaments, leagues, and events with ease. You can schedule games, assign officials, and track scores and standings all in one place."
    },
    {
      question: "Is there a free trial available?",
      answer:
        "Yes, we offer a free trial period, simply click on start free trial on any plan of your choice."
    },
    {
      question: "What kind of team management tools do you offer?",
      answer:
        "We offer tools like team lineups management, chatrooms, team payments, tasks assignment and event management for all teams."
    }
  ];

  if (!faqs1 || faqs1.length === 0) faqs1 = defaultFaqs1;
  if (!faqs2 || faqs2.length === 0) faqs2 = defaultFaqs2;
  const [activeQuestionIndex1, setActiveQuestionIndex1] = useState(null);
  const [activeQuestionIndex2, setActiveQuestionIndex2] = useState(null);

  const toggleQuestion1 = questionIndex => {
    if (activeQuestionIndex1 === questionIndex) setActiveQuestionIndex1(null);
    else setActiveQuestionIndex1(questionIndex);
  };

  const toggleQuestion2 = questionIndex => {
    if (activeQuestionIndex2 === questionIndex) setActiveQuestionIndex2(null);
    else setActiveQuestionIndex2(questionIndex);
  };

  return (
    <Container>
      <Content>
        {subheading ? <Subheading>{subheading}</Subheading> : null}
        <Heading>{heading}</Heading>
        {/* <Description>{description}</Description> */}

        <TwoColumn>
          <Column tw=" lg:block lg:w-6/12 flex-shrink-0">
            <FAQContent>
              <FAQSContainer>
                {faqs1.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion1(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex1 === index ? <UpIcon /> : <DownIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex1 === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                    <Divider />
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
          <Column>
            <FAQContent>
              <FAQSContainer>
                {faqs2.map((faq, index) => (
                  <FAQ
                    key={index}
                    onClick={() => {
                      toggleQuestion2(index);
                    }}
                    className="group"
                  >
                    <Question>
                      <QuestionText>{faq.question}</QuestionText>
                      <QuestionToggleIcon>
                        {activeQuestionIndex2 === index ? <UpIcon /> : <DownIcon />}
                      </QuestionToggleIcon>
                    </Question>
                    <Answer
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "16px" },
                        collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex2 === index ? "open" : "collapsed"}
                      transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      {faq.answer}
                    </Answer>
                    <Divider />
                  </FAQ>
                ))}
              </FAQSContainer>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
