import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import MainFeature from "components/features/ThreeColCenteredStats.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import sportsGifSrc from "images/impact.jpg";


import axios from "axios";
import Notification from "components/features/NotificationModal.js";

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"
import podiumGifSrc from "images/podium.gif";
import { Oval } from 'react-loader-spinner'


const Container = tw.div`relative`;
// max-w-screen-xl mx-2 md:mx-6
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between  xl:mx-auto items-center`;
const Column = tw.div`w-full max-w-none mx-0`;
const Row = tw.div`w-full pt-4 flex flex-col md:flex-row max-w-md mx-auto md:max-w-none md:mx-0`;
const FormColumn = tw(Column)`md:w-6/12  bg-primary-100 flex-shrink-0 relative md:px-16  `;
const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-24 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
    tw``,
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`mx-auto mx-8 md:mx-6   pt-16 md:pt-24  pb-40 md:pb-32 ml-8 lg:ml-32 max-w-xl text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-secondary-700 text-left text-4xl sm:text-5xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-left text-xl lg:text-xl font-light leading-relaxed text-secondary-600`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
    tw`mt-8 w-64 md:mt-8 text-sm inline-block flex  justify-center flex mx-auto md:mx-0`,
    props.buttonRounded && tw`rounded-full`
]);


const FormContainer = styled.div`
  ${tw`h-screen mx-auto mx-8 md:mx-6  pt-24 md:pt-32  pb-20 md:pb-12   max-w-4xl text-secondary-600 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-light`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-900 text-base font-medium tracking-wide border-b-2 py-2 text-gray-900 hocus:border-primary-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-600`}
    }
  }
`;

const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-light  text-md`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full md:w-32 mt-6 py-3 flex justify-center text-gray-100 bg-primary-500 rounded-full font-light tracking-wide shadow-lg  text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-primary-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const TagButton = styled.a`
${tw`relative mr-4 left-0 right-0 top-0 bottom-0 bg-primary-100  font-light  text-secondary-600  px-4 py-3   rounded leading-none `}
`
    ;

const UnselectedButton = styled.a`
${tw`relative md:mr-4 flex justify-center mb-2 left-0 right-0 top-0 bottom-0 bg-primary-100 border border-primary-500  text-primary-500  px-4 py-3   rounded-full  flex items-center justify-center leading-none focus:outline-none hover:text-gray-100  hover:bg-primary-500 transition  duration-300`}
`
    ;

const SelectedButton = styled.a`
${tw`relative md:mr-4 flex justify-center mb-2 left-0 right-0 top-0 bottom-0 bg-primary-300 border border-primary-500  text-gray-100  px-4 py-3   rounded-full  flex items-center justify-center leading-none focus:outline-none hover:text-gray-100  hover:bg-primary-200 transition  duration-300`}
`
    ;




export default () => {

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [type, setType] = useState("Athlete");
    const [loading, setLoading] = useState(false);
    const [hasNotification, setHasNotification] = useState(false);
    const [data, setData] = useState({ success: false });
    const [show, setShow] = useState(true);
    async function postEmail(e) {
        e.preventDefault();
        setLoading(true);
        try {
            console.log("SS");



            var { data } = await axios.post("https://www.subscribe.buzza.app/subscribe", {
                "name": name,
                "email": email,
                "type": type


            },);

            console.log(data);
            setData(data)
            setHasNotification(true);
            setShow(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            console.log();

            setData(error.response.data)
            setHasNotification(true);
            setShow(true);
            setLoading(false);
        }
    }
    return (
        <AnimationRevealPage>
            <Header />





            <Container>
                <TwoColumn>
                    <FormColumn>
                        <FormContainer>
                            <form onSubmit={postEmail}>


                                <Column>
                                    <InputContainer>
                                        <Label htmlFor="name-input">Your Name*</Label>
                                        <Input id="name-input" type="text" onChange={(e) => setName(e.target.value)} name="name" placeholder="" required />
                                    </InputContainer>
                                    <InputContainer>
                                        <Label htmlFor="email-input">Your Email Address*</Label>
                                        <Input id="email-input" type="email" onChange={(e) => setEmail(e.target.value)} name="email" placeholder="" required />
                                    </InputContainer>
                                </Column>
                                <Column>
                                    <InputContainer tw="flex-1">
                                        <Label htmlFor="name-input">{"I am a(n)"}</Label>
                                        <Row>
                                            {type == "Athlete" ? (<SelectedButton >Athlete</SelectedButton>) :
                                                (<UnselectedButton onClick={(e) => setType("Athlete")}>Athlete</UnselectedButton>
                                                )}
                                            {type == "Organisation" ? (<SelectedButton >Organisation</SelectedButton>) :
                                                (<UnselectedButton onClick={(e) => setType("Organisation")}>Organisation</UnselectedButton>
                                                )}</Row>
                                    </InputContainer>
                                </Column>


                                {loading ? (
                                    <SubmitButton ><Oval

                                        height={25}
                                        width={45}
                                        color="#fcae93"
                                        wrapperStyle={{}}

                                        visible={true}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#ba4014"
                                        strokeWidth={7}
                                        strokeWidthSecondary={7}

                                    /></SubmitButton>
                                ) : (
                                    <SubmitButton type="submit">Submit</SubmitButton>

                                )}
                            </form>
                        </FormContainer>
                    </FormColumn>
                    <TextColumn textOnLeft={true}>
                        <TextContent>
                            <Subheading><TagButton>Early Access</TagButton></Subheading>
                            <Heading>Be the first to know when we launch!</Heading>
                            <Description>Our platform is launching soon. Sign up to be the first to access Buzza.</Description>
                            {/* <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
                        </TextContent>
                    </TextColumn>
                </TwoColumn>
            </Container>

            {/* <Footer /> */}
            {hasNotification ? (<Notification show={show} setShow={setShow} success={data['success']} heading={data['heading']} description={data['message']} />) : (<div></div>)}

        </AnimationRevealPage>
    );
};
