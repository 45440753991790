import React, { useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import Features2 from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import DashedBorder from "components/features/DashedBorderSixFeatures.js";
import TabGrid from "components/cards/TabCardGrid.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import landingimageone from "../images/landing/landingone.png";
import landingimagetwo from "../images/landing/landingtwo.png";
import landingimagethree from "../images/landing/landingthree.png";
import Features3 from "components/features/ThreeColSimple.js";
import LogoImage from "images/logowhite.svg";
import FAQ from "components/faqs/SimpleWithSideImage.js";


export default () => {
  const Subheading = tw.span`tracking-wider text-xl font-medium text-center`;
  const HighlightedText = tw.a` text-primary-500    `;
  const HighlightedTextInverse = tw.span`bg-gray-100 font-buzzz text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`object-cover`;
  const LogoImg = tw.img`w-40 mx-auto md:-ml-4 flex justify-center`;
  const OrangeText = tw.span`text-primary-500 font-bold `;

  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Take Your    <HighlightedText>Sports  Community </HighlightedText>to the  Next Level!</>}
        description={<>We are dedicated to making sports management  more accessible and hassle-free for sports  organizations, communities, and enthusiasts. </>}
        imageSrc={landingimageone}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
        watchVideoButtonText="Meet The Chefs"
      />







      <Features2 heading={<>The Right Management Platform <br />for the Winning Team</>} description={<>Get access to innovative tools for team management and fan engagement across <OrangeText>40+</OrangeText> sports.</>} />


      <MainFeature2
        subheading={<>Account features suited for your sporting needs</>}
        heading={<>Athletes, sport organizations or enthusiasts we’ve got you!</>}
        description=""
        primaryButtonText=""
        primaryButtonUrl=""
        imageInsideDiv={false}
        imageSrc={landingimagethree}
        imageCss={Object.assign(tw`hidden`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
        onecolumn={true}
      />

      <MainFeature2
        subheading={<>CONNECT AND GROW YOUR FANS BASE</>}
        heading={<>Buzza for Individuals</>}
        description="This is an individual account that enables you to connect better and manage all your sporting activities with ease. It is suitable for athletes, coaches, parents, fans, scouts and referees."
        primaryButtonText="Subscribe"
        primaryButtonUrl="/subscribe"
        imageInsideDiv={false}
        imageSrc={landingimagetwo} imageCss={Object.assign(tw`bg-cover rounded-sm`, imageCss)}
        imageContainerCss={tw`md:w-3/12 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
      />

      <MainFeature2
        subheading={<>SCALE UP YOUR SPORT COMMUNITIES EASILY </>}
        heading={<>Buzza for Businesses</>}
        description="This collaborative account enables groups (teams, organizers, schools, clubs, institutions, academies, etc.) to better engage their fans and manage their sporting activities. With this account, you can create leagues, tournaments and  olympiads, manage teams, and improve fan engagement."
        primaryButtonText="Subscribe"
        primaryButtonUrl="/subscribe"
        imageInsideDiv={false}
        imageSrc={landingimagethree} imageCss={Object.assign(tw`bg-cover rounded-xl`, imageCss)}
        imageContainerCss={tw`md:w-3/12 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      {/* <Testimonial
        subheading=""
        heading={<>Sporting communities <HighlightedText>Love Us.</HighlightedText></>}
      /> */}

      <FAQ
        // imageSrc={customerSupportIllustrationSrc}
        // imageContain={true}
        // imageShadow={false}
        description="Here are some frequently asked questions about Buzza from our loving customers. Should you have any other questions, feel free to reach out via our contact channels"
        subheading="FAQs"
        heading={
          <>
            Frequently Asked  <span tw="text-primary-500">Questions </span>
          </>
        }
      />
      <DownloadApp
        text={<>Take your game to the next level with <LogoImg src={LogoImage} /> </>}
      />
      {/* import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
<SubscribeNewsLetterForm /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
